// THOM Dropdown
// import hoverIntent from "jquery-hoverintent";
import mCustomScrollbar from "malihu-custom-scrollbar-plugin";
import mousewheel from "jquery-mousewheel";


export function thomDropdown () {
	/* MOBILE Action */
	var mob_nav = function (elem) {
		$(elem).find('.thom-dropdown--label').click(function (e) {
			e.preventDefault();
			$('.thom-dropdown').not(elem).removeClass('is-open');

			if ($(elem).hasClass('is-open')) {
				// $(elem).removeClass('is-open');
				close(elem);
			} else {
				// $(elem).addClass('is-open');
				open(elem);
			}

			// scrollbar(elem);
			return false;
		});
	}

	/* Open Select */
	var open = function (elem) {
		$(elem).addClass('is-open');
		scrollbar(elem);
	};

	/* Control for scrollbar */
	var scrollbar = function (elem) {
		var elems = $(elem).find('.thom-dropdown--options-item');
		var elems_n = elems.length;
		var elems_H = 0;
		var elems_max = $(elem).data('max');

		elems.not('.thom-search--filter-btn').click(function () {
			close(elem);
		})
		
		if ($.isNumeric(elems_max) && elems_n > elems_max) {
			console.log(elems.length)
			if (elems.length > elems_max) {
				elems.each(function (i) {
					elems_H += $(this).outerHeight(true);
					if (! --elems_max) {
						$(elem).find('.thom-dropdown--options').height(elems_H).css('overflow', 'hidden');
					}

					if(i >= elems.length - 1){
						if ($(elem).find('.thom-dropdown--options').hasClass('mCustomScrollbar')) {
							$(elem).find('.thom-dropdown--options').mCustomScrollbar("update");
						} else {
							$(elem).find('.thom-dropdown--options').mCustomScrollbar();
						}
					}
				})
			}
		}
	}

	/* Close Select */
	var close = function (elem) {
		$(elem).removeClass('is-open');
		if ($(elem).find('.mCustomScrollbar')) {
			$(elem).find('.thom-dropdown--options').mCustomScrollbar("destroy");
		}
	};

	let dropdown = document.getElementsByClassName('thom-dropdown');
	for( var i = 0; i <= dropdown.length; i++) {
		var _ = dropdown[i];
		if (_) {
			mob_nav(_);
		}
	}
}
export default thomDropdown
